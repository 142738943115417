@keyframes logoRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo-rotation {
  animation: logoRotation 50s linear infinite;
}

.logo-size {
  width: 160px;
  height: 160px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .logo-size {
    width: 190px;
    height: 190px;
  }
}

@media (min-width: 992px) {
  .logo-size {
    width: 240px;
    height: 240px;
  }
}

.portal-logo-size {
  fill: "black";
  stroke: "black";
  width: 42px;
  height: 42px;
}

@media (min-width: 768px) {
  .portal-logo-size {
    width: 50px;
    height: 50px;
  }
}

.header-logo {
  width: 38px;
  height: 38px;
}

@media (min-width: 768px) {
  .header-logo {
    width: 44px;
    height: 44px;
  }
}
